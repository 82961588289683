@font-face {
	font-family: $fontBarlowSemiCondensed;
	src: url("../fonts/BarlowSemiCondensed-Regular.ttf");
	font-weight: $fontWeightRegular;
	font-style: normal;
}

@font-face {
	font-family: $fontBarlowSemiCondensed;
	src: url("../fonts/BarlowSemiCondensed-Bold.ttf");
	font-weight: $fontWeightBold;
	font-style: normal;
}

@font-face {
	font-family: $fontWingdings3;
	src: url("../fonts/Wingdings3.ttf");
	font-weight: $fontWeightRegular;
	font-style: normal;
    unicode-range: U+F075;
}
