@mixin dark-mode {
    @media (prefers-color-scheme: dark) {
        @content;
    }
}

%section {
	background-color: #fff;
	@include dark-mode {
		background-color: $colorDarkSurface;
	}
}

