@import 'vars';
@import 'fonts';
@import 'abstracts';

* {
	box-sizing: border-box;
	margin:0;
	padding:0;
}

html {
	overflow: scroll;
	margin: 0;
}

body {
	color: $colorGray;
	font-family: $bodyFont;
	@include dark-mode {
		color: $colorLightGray;
	}

}

main {
	background-color: $colorTransparentBlue;
	@include dark-mode {
		background-color: $colorDarkBlue;
	}
	padding: 178px 0;
}

section {
	@extend %section;
	padding: 58px 0;
}

.content-wrapper {
	width: 1440px;
	max-width: 90%;
	margin: auto;
}

footer {
	@extend %section;
	padding: 51px 0;
	img {
		max-width: 100%;
		margin-bottom: 30px;
	}

	ul {
		list-style-type: none;
		@media screen and (min-width: 1024px) {
			display: flex;
		}

		li {
			margin-right: 50px;
			margin-bottom: 15px;
			a {
				text-decoration: none;
				color: $colorGray;
				font-size: 18px;
				line-height: 21px;
				@include dark-mode {
					color: white;
				}

				&:hover {
					color: $colorLightGray;
				}
			}
		}
	}

	p {
		font-size: 18px;
		line-height: 21px;
		margin: 0;
	}
}

h1, h2 {
	color: $colorDarkBlue;
	font-family: $headlineFont;
	font-size: 54px;
	line-height: 56px;
	text-transform: uppercase;
	margin-bottom: 36px;
	@include dark-mode {
		color: $colorLightBlue;
	}
}

h2 {
	text-align: center;
}

p, ul {
	margin-bottom: 30px;
}

a {
	color: $colorBlue;
	@include dark-mode {
		color: $colorLightBlue;
	}
	&:hover {
		color: $colorLightBlue;

		@include dark-mode {
			color: $colorBlue;
		}
	}
}

.center {
	text-align: center;
}

/* Home Page Styles */

#splitter {
	@extend %section;
	padding: 58px 0;
	box-shadow: 10px 10px 20px rgba(0,0,0,.16);
	.content-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-size: 28px;
			line-height: 34px;
			font-weight: $fontWeightBold;
			margin-bottom: 30px;
			font-family: $headlineFont;
		}

		.button {
			max-width: 625px;
			width: 100%;
			border-radius: 40px;
			background-color: $colorDarkBlue;
			border: 0;
			padding: 28px;
			color: #fff;
			font-size: 28px;
			line-height: 34px;
			font-weight: $fontWeightBold;
			margin-top: 29px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $headlineFont;
			cursor: pointer;
			text-decoration: none;

			&:after {
				content: '\F075';
				font-family: $fontWingdings3;
				color: #76BD22;
				margin-left: 11px;
				position: relative;
				top: 2px;
			}

			&:hover {
				background-color: $colorBlue;
			}
		}
	}

}

/* End Home Page Styles */

/* Cookie Policy Page Styles */

#ot-sdk-btn.ot-sdk-show-settings {
    font-family: $headlineFont;
    text-transform: uppercase !important;
    border-radius: 35px !important;
    text-transform: uppercase !important;
    font-size: 28px !important;
    font-weight: 600 !important;
    padding: 1px 25px !important;
    border: 2px solid $colorDarkBlue !important;
    background-color: #D39F10 !important;
    color: $colorDarkBlue !important;
	margin-bottom: 30px;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
    background-color: #FFF !important;
    color: $colorDarkBlue !important;
    border: 2px solid $colorDarkBlue !important;
}

/* This is normally global but only the cookie page has external links */@at-root

#exit-interstitial {
	display: none;
	background-color: rgba($color: #404040, $alpha: 0.4);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	#exit-interstitial-content {
		max-width: 700px;
		width: 90%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 40px;
		@extend %section;

		a {
			padding: 10px 20px;
			background-color: $colorDarkBlue;
			color: #fff;
			margin-right: 50px;
			text-decoration: none;
			border-radius: 4px;
			font-weight: $fontWeightBold;
		}
	}
}

/* End Cookie Policy Page Styles */