$fontBarlowSemiCondensed: 'BarlowSemiCondensed';
$fontWingdings3: 'Wingdings3';
$bodyFont: 'Arial', sans-serif;
$headlineFont: $fontBarlowSemiCondensed, sans-serif;

// Font weights
// Although regular and bold are synonymous with CSS defaults, they are included here to be complete.
$fontWeightThin: 100;
$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;


$colorGray: #707070;
$colorLightGray: #c9c5c5;
$colorDarkBlue: #004877;
$colorTransparentBlue: rgba(132, 189, 209, 0.3);
$colorBlue: #337ab7;
$colorDarkSurface: #121212;
$colorLightBlue: #86c5d8;